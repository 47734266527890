// 1
import projectSectionImage_1 from "./projectImage/project_pics/01_project/projectSection.jpg";
import projectImage_1_1 from "./projectImage/project_pics/01_project/image1.jpg";
import projectImage_1_2 from "./projectImage/project_pics/01_project/image2.jpg";
import projectImage_1_3 from "./projectImage/project_pics/01_project/image3.jpg";
import projectImage_1_4 from "./projectImage/project_pics/01_project/image4.jpg";

// 2
import projectSectionImage_2 from "./projectImage/project_pics/02_project/projectSection.jpg";
import projectImage_2_1 from "./projectImage/project_pics/02_project/image1.jpg";
import projectImage_2_2 from "./projectImage/project_pics/02_project/image2.jpg";
import projectImage_2_3 from "./projectImage/project_pics/02_project/image3.jpg";
import projectImage_2_4 from "./projectImage/project_pics/02_project/image4.jpg";

// 3
import projectSectionImage_3 from "./projectImage/project_pics/03_project/projectSection.jpg";
import projectImage_3_1 from "./projectImage/project_pics/03_project/image1.jpg";
import projectImage_3_2 from "./projectImage/project_pics/03_project/image2.jpg";
import projectImage_3_3 from "./projectImage/project_pics/03_project/image3.jpg";
import projectImage_3_4 from "./projectImage/project_pics/03_project/image4.jpg";

// 4
import projectSectionImage_4 from "./projectImage/project_pics/04_project/projectSection.jpg";
import projectImage_4_1 from "./projectImage/project_pics/04_project/image1.jpg";
import projectImage_4_2 from "./projectImage/project_pics/04_project/image2.jpg";
import projectImage_4_3 from "./projectImage/project_pics/04_project/image3.jpg";
import projectImage_4_4 from "./projectImage/project_pics/04_project/image4.jpg";

// 5
import projectSectionImage_5 from "./projectImage/project_pics/05_project/projectSection.jpg";
import projectImage_5_1 from "./projectImage/project_pics/05_project/image1.jpg";
import projectImage_5_2 from "./projectImage/project_pics/05_project/image2.jpg";
import projectImage_5_3 from "./projectImage/project_pics/05_project/image3.jpg";

// 6
import projectSectionImage_6 from "./projectImage/project_pics/06_project/projectSection.jpg";
import projectImage_6_1 from "./projectImage/project_pics/06_project/image1.jpg";
import projectImage_6_2 from "./projectImage/project_pics/06_project/image2.jpg";
import projectImage_6_3 from "./projectImage/project_pics/06_project/image3.jpg";

// 7
import projectSectionImage_7 from "./projectImage/project_pics/07_project/projectSection.jpg";
import projectImage_7_1 from "./projectImage/project_pics/07_project/image1.jpg";
import projectImage_7_2 from "./projectImage/project_pics/07_project/image2.jpg";

// 8
import projectSectionImage_8 from "./projectImage/project_pics/08_project/projectSection.jpg";
import projectImage_8_1 from "./projectImage/project_pics/08_project/image1.jpg";
import projectImage_8_2 from "./projectImage/project_pics/08_project/image2.jpg";
import projectImage_8_3 from "./projectImage/project_pics/08_project/image3.jpg";

// 9
import projectSectionImage_9 from "./projectImage/project_pics/09_project/projectSection.jpg";
import projectImage_9_1 from "./projectImage/project_pics/09_project/image1.jpg";
import projectImage_9_2 from "./projectImage/project_pics/09_project/image2.jpg";
import projectImage_9_3 from "./projectImage/project_pics/09_project/image3.jpg";
import projectImage_9_4 from "./projectImage/project_pics/09_project/image4.jpg";

// 10
import projectSectionImage_10 from "./projectImage/project_pics/10_project/projectSection.jpg";
import projectImage_10_1 from "./projectImage/project_pics/10_project/image1.jpg";
import projectImage_10_2 from "./projectImage/project_pics/10_project/image2.jpg";
import projectImage_10_3 from "./projectImage/project_pics/10_project/image3.jpg";
import projectImage_10_4 from "./projectImage/project_pics/10_project/image4.jpg";

// 11
import projectSectionImage_11 from "./projectImage/project_pics/11_project/projectSection.jpg";
import projectImage_11_1 from "./projectImage/project_pics/11_project/image1.jpg";
import projectImage_11_2 from "./projectImage/project_pics/11_project/image2.jpg";
import projectImage_11_3 from "./projectImage/project_pics/11_project/image3.jpg";
import projectImage_11_4 from "./projectImage/project_pics/11_project/image4.jpg";
import projectImage_11_5 from "./projectImage/project_pics/11_project/image5.jpg";

// 12
import projectSectionImage_12 from "./projectImage/project_pics/12_project/projectSection.jpg";
import projectImage_12_1 from "./projectImage/project_pics/12_project/image1.jpg";
import projectImage_12_2 from "./projectImage/project_pics/12_project/image2.jpg";
import projectImage_12_3 from "./projectImage/project_pics/12_project/image3.jpg";
import projectImage_12_4 from "./projectImage/project_pics/12_project/image4.jpg";

// 13
import projectSectionImage_13 from "./projectImage/project_pics/13_project/projectSection.jpg";
import projectImage_13_1 from "./projectImage/project_pics/13_project/image1.jpg";
import projectImage_13_2 from "./projectImage/project_pics/13_project/image2.jpg";

// 14
import projectSectionImage_14 from "./projectImage/project_pics/14_project/projectSection.jpg";
import projectImage_14_1 from "./projectImage/project_pics/14_project/image1.jpg";
import projectImage_14_2 from "./projectImage/project_pics/14_project/image2.jpg";

// 15
import projectSectionImage_15 from "./projectImage/project_pics/15_project/projectSection.jpg";
import projectImage_15_1 from "./projectImage/project_pics/15_project/image1.jpg";
import projectImage_15_2 from "./projectImage/project_pics/15_project/image2.jpg";

// 16
import projectSectionImage_16 from "./projectImage/project_pics/16_project/projectSection.jpg";
import projectImage_16_1 from "./projectImage/project_pics/16_project/image1.jpg";

// 17
import projectSectionImage_17 from "./projectImage/project_pics/17_project/projectSection.jpg";
import projectImage_17_1 from "./projectImage/project_pics/17_project/image1.jpg";
import projectImage_17_2 from "./projectImage/project_pics/17_project/image2.jpg";

// 18
import projectSectionImage_18 from "./projectImage/project_pics/18_project/projectSection.jpg";
import projectImage_18_1 from "./projectImage/project_pics/18_project/image1.jpg";
import projectImage_18_2 from "./projectImage/project_pics/18_project/image2.jpg";
import projectImage_18_3 from "./projectImage/project_pics/18_project/image3.jpg";

export default [
    {
        sys: {
            id: "1"
        },
        fields: {
            projectSectionImage: projectSectionImage_1,
            heading: "Designer’s Adobe, Greater Noida",
            description:
                "A creative, minimal and contemporary expression represents profession of the working couple. White and wood binds all the interior spaces with fusion of distinctive colors to personalize individual’s space. Thus pink and green were composed with white for the daughter’s room.",
            category: {
                interior: true,
                architecture: false,
                residential: true,
                commercial: false,
                institutional: false,
            }
        },
        images: [
            projectImage_1_1,
            projectImage_1_2,
            projectImage_1_3,
            projectImage_1_4,
        ]
    },
    {
        sys: {
            id: "2"
        },
        fields: {
            projectSectionImage: projectSectionImage_2,
            heading: "M’s CASA, Greater Noida",
            description:
                "The design primarily focuses the utility and multi-functionality of spaces and furniture incorporated with aesthetics reflecting dynamic personalities of clients working in hospitality industry. As a result, bold and minimal expression becomes statement of the cozy interiors.",
            category: {
                interior: true,
                architecture: false,
                residential: true,
                commercial: false,
                institutional: false,
            }
        },
        images: [
            projectImage_2_1,
            projectImage_2_2,
            projectImage_2_3,
            projectImage_2_4
        ]
    },
    {
        sys: {
            id: "3"
        },
        fields: {
            projectSectionImage: projectSectionImage_3,
            heading: "Apartment @Jaypee Greens, Greater Noida",
            description:
                "The interior design uses minimal, cozy and bold expression to induce comfortable and rejuvenating environment within the apartment situated abutting golf course with a lake view. Minimal  interior background was created to highlight bold and fine crafted décor elements",
            category: {
                interior: true,
                architecture: false,
                residential: true,
                commercial: false,
                institutional: false,
            }
        },
        images: [
            projectImage_3_1,
            projectImage_3_2,
            projectImage_3_3,
            projectImage_3_4
        ]
    },
    {
        sys: {
            id: "4"
        },
        fields: {
            projectSectionImage: projectSectionImage_4,
            heading: "Zenniya, Punjabi Bagh, New Delhi",
            description:
                "The interior concept of the unisex salon evolves from Black & White representing masculinity and contrasting Red symbolizing feminism. Straight lines and shapes compose the interiors geometrically gaining attention to user’s senses.",
            category: {
                interior: true,
                architecture: false,
                residential: false,
                commercial: true,
                institutional: false,
            }
        },
        images: [
            projectImage_4_1,
            projectImage_4_2,
            projectImage_4_3,
            projectImage_4_4
        ]
    },
    {
        sys: {
            id: "5"
        },
        fields: {
            projectSectionImage: projectSectionImage_5,
            heading: "Corporate Office- JD Institute, Hauz Khas, New Delhi",
            description:
                "Minimal and delicate details build the ambiance of the corporate office of the interior and fashion institute in urban village settings. Transparency and bold design elements representing the values and essence of the organization.",
            category: {
                interior: true,
                architecture: false,
                residential: false,
                commercial: false,
                institutional: true,
            }
        },
        images: [
            projectImage_5_1,
            projectImage_5_2,
            projectImage_5_3
        ]
    },
    {
        sys: {
            id: "6"
        },
        fields: {
            projectSectionImage: projectSectionImage_6,
            heading: "RD Jewelers, Dwarka, New Delhi",
            description:
                "Fine carved elements, innovation in application and richness of material becomes obvious choice for the jewelry showroom catching the attention of visitors mind by using customized screen, glass reflection and carefully lights highlighting the merchandise.",
            category: {
                interior: true,
                architecture: false,
                residential: false,
                commercial: true,
                institutional: false,
            }
        },
        images: [
            projectImage_6_1,
            projectImage_6_2,
            projectImage_6_3
        ]
    },
    {
        sys: {
            id: "7"
        },
        fields: {
            projectSectionImage: projectSectionImage_7,
            heading: "Halcyon Thai Spa, MGF Megapolis, Gurugram",
            description:
                "Composition of elements enriching multisensory experience becomes approach to design of Thai spa creating an environment of tranquility, rejuvenation and mindfulness. Carefully designed elements, light sources, mirrors, colors as well as materials induce metacognition helpful to realization and relaxation.",
            category: {
                interior: true,
                architecture: false,
                residential: false,
                commercial: true,
                institutional: false,
            }
        },
        images: [
            projectImage_7_1,
            projectImage_7_2
        ]
    },
    {
        sys: {
            id: "8"
        },
        fields: {
            projectSectionImage: projectSectionImage_8,
            heading: "JD Institute of Fashion Technology, Gurugram Center",
            description:
                "Angular lines, lights and origami inspired furniture becomes highlight of interior spaces of the institute of creative studies. Transparent cubicles carve spaces for administrative works almost disappear in front of concrete wall panels. The space inspires creative imagination through its own structure and hidden order.",
            category: {
                interior: true,
                architecture: false,
                residential: false,
                commercial: false,
                institutional: true,
            }
        },
        images: [
            projectImage_8_1,
            projectImage_8_2,
            projectImage_8_3
        ]
    },
    {
        sys: {
            id: "9"
        },
        fields: {
            projectSectionImage: projectSectionImage_9,
            heading: "JD Institute of Fashion Technology, North Campus Center",
            description:
                "Straight lines, lights and concrete wall panel inspired inspire creative imagination through its own structure and hidden order. Large designed wall and graphics inspire the budding designers and created environment of incubation of ideas.",
            category: {
                interior: true,
                architecture: false,
                residential: false,
                commercial: false,
                institutional: true,
            }
        },
        images: [
            projectImage_9_1,
            projectImage_9_2,
            projectImage_9_3,
            projectImage_9_4
        ]
    },
    {
        sys: {
            id: "10"
        },
        fields: {
            projectSectionImage: projectSectionImage_10,
            heading: "Techie’s Home, Greater Noida West",
            description:
                "Contemporary, quirky and stylish design elements represent client’s personality in the interior spaces. Use of metal, bold colors, metallic edges and mirror reflection makes space spacious, classy and luxurious. The design incorporates various parameters through its unique expression being reasonable and responsive.",
            category: {
                interior: true,
                architecture: false,
                residential: true,
                commercial: false,
                institutional: false,
            }
        },
        images: [
            projectImage_10_1,
            projectImage_10_2,
            projectImage_10_3,
            projectImage_10_4
        ]
    },
    {
        sys: {
            id: "11"
        },
        fields: {
            projectSectionImage: projectSectionImage_11,
            heading: "Apartment @Ramprastha Greens, Ghaziabad",
            description:
                "Spaces were carve out from existing structure by altering and addition making various internal spaces visually connected, grand and inducing natural light. Use of natural materials, carefully located design elements and natural light stimulate users mind while experiencing the interiors induces positivity.",
            category: {
                interior: true,
                architecture: false,
                residential: true,
                commercial: false,
                institutional: false,
            }
        },
        images: [
            projectImage_11_1,
            projectImage_11_2,
            projectImage_11_3,
            projectImage_11_4,
            projectImage_11_5
        ]
    },
    {
        sys: {
            id: "12"
        },
        fields: {
            projectSectionImage: projectSectionImage_12,
            heading: "Solanki’s Residence, Janakpuri, New Delhi",
            description:
                "The residence is an outcome of fusion between dynamic and traditional thoughts of two generations living together represented in a subtle interior-architectural language. Massing and visual expression has a contemporary approach whereas planning of the house has a reflection of traditional life style.",
            category: {
                interior: false,
                architecture: true,
                residential: true,
                commercial: false,
                institutional: false,
            }
        },
        images: [
            projectImage_12_1,
            projectImage_12_2,
            projectImage_12_3,
            projectImage_12_4
        ]
    },
    {
        sys: {
            id: "13"
        },
        fields: {
            projectSectionImage: projectSectionImage_13,
            heading: "Captain’s Residence, Gurugram",
            description:
                "The multistoried house has a studio apartment, independent residential unit floor wise as well as a basement. The design of the residence incorporates multiple spatial requirements while still maintaining the essence of low rise contemporary house. Use of local materials like quartzite and brick clad in a modern style makes building part of the urban fabric.",
            category: {
                interior: false,
                architecture: true,
                residential: true,
                commercial: false,
                institutional: false,
            }
        },
        images: [
            projectImage_13_1,
            projectImage_13_2
        ]
    },
    {
        sys: {
            id: "14"
        },
        fields: {
            projectSectionImage: projectSectionImage_14,
            heading: "Residence @Muzaffarnagar",
            description:
                "Independent residence designed for joint family. The facade of the residence is designed in contemporary form and style using local sandstone, granite and external wood. Metal edging, pergola and tapered niche windows are silent features of the design cutting the harsh sun and giving the build form a finishing touch.",
            category: {
                interior: false,
                architecture: true,
                residential: true,
                commercial: false,
                institutional: false,
            }
        },
        images: [
            projectImage_14_1,
            projectImage_14_2
        ]
    },
    {
        sys: {
            id: "15"
        },
        fields: {
            projectSectionImage: projectSectionImage_15,
            heading: "City Mall & Hotel, Jodhpur",
            description:
                "Concept design proposes vernacular elements along with contemporary style to form unique expression for the proposed city mall and hotel at Jodhpur. The design fabric contains traditional elements like canopy, jharokha and red sandstone reflecting the rich historical context of the city.",
            category: {
                interior: false,
                architecture: true,
                residential: false,
                commercial: true,
                institutional: false,
            }
        },
        images: [
            projectImage_15_1,
            projectImage_15_2
        ]
    },
    {
        sys: {
            id: "16"
        },
        fields: {
            projectSectionImage: projectSectionImage_16,
            heading: "Mall & Hotel, Guwahati",
            description:
                "Dual functions proposed in the monolithic build form. Lower two floors are dedicated to shopping whereas upper floors are retained for hotel purpose. The planning segregates the functions with separate access to utilities. Functions govern the form of the contemporary building.",
            category: {
                interior: false,
                architecture: true,
                residential: false,
                commercial: true,
                institutional: false,
            }
        },
        images: [
            projectImage_16_1
        ]
    },
    {
        sys: {
            id: "17"
        },
        fields: {
            projectSectionImage: projectSectionImage_17,
            heading: "100 Beds Hospital, Jammu",
            description:
                "Concept planning of the hospital is based on combination of smaller functional branch connecting to common spine. This forms series of courts at rear and large forecourt defining the main entrance. The design of the complex responds to climate and surrounding context.",
            category: {
                interior: false,
                architecture: true,
                residential: false,
                commercial: true,
                institutional: false,
            }
        },
        images: [
            projectImage_17_1,
            projectImage_17_2,
        ]
    },
    {
        sys: {
            id: "18"
        },
        fields: {
            projectSectionImage: projectSectionImage_18,
            heading: "SPA New Campus – Competition Entry",
            description:
                "The landmark style of the architecture evolve from site surrounding, climatic context and functional requirement creating spaces for incubation of ideas for the school of architecture and planning. Geometrical forms and bold design expression assist sustainable environment as well as inspire behavior.",
            category: {
                interior: false,
                architecture: true,
                residential: false,
                commercial: false,
                institutional: true,
            }
        },
        images: [
            projectImage_18_1,
            projectImage_18_2,
            projectImage_18_3,
        ]
    }
];
