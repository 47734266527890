import React from 'react'

export default function Error() {
    return (
        <>
            <div className="team-banner defaultTeamBanner">
                <h1>Page Not Found!</h1>
            </div>
        </>
    )
}
